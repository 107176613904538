/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*============================================================================
          # Skip link focus fix
          * Helps with accessibility for keyboard only users.
          * Learn more: https://git.io/vWdr2
        ==============================================================================*/

        ( function() {
          var isIe = /(trident|msie)/i.test( navigator.userAgent );

          if ( isIe && document.getElementById && window.addEventListener ) {
            window.addEventListener( 'hashchange', function() {
              var id = location.hash.substring( 1 ),
                element;

              if ( ! ( /^[A-z0-9_-]+$/.test( id ) ) ) {
                return;
              }

              element = document.getElementById( id );

              if ( element ) {
                if ( ! ( /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) ) {
                  element.tabIndex = -1;
                }

                element.focus();
              }
            }, false );
          }
        } )();
        //End skip link focus fix

        /*============================================================================
          # Remove hover effect on touch devices
        ==============================================================================*/

        function hasTouch() {
            return false && ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
        }

        if (hasTouch()) { // remove all :hover stylesheets
            try { // prevent exception on browsers not supporting DOM styleSheets properly
                for (var si in document.styleSheets) {
                    var styleSheet = document.styleSheets[si];
                    if (!styleSheet.rules) continue;

                    for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) continue;

                        if (styleSheet.rules[ri].selectorText.match(':hover')) {
                            styleSheet.deleteRule(ri);
                        }
                    }
                }
            } catch (ex) {}
        }

        /*============================================================================
          # Scroll to top
        ==============================================================================*/


        $(function(){
          $(".scroll").click(function() {
            $("html,body").animate({scrollTop:$(".thetop").offset().top
          },"500");return false;
          });
        });

        /*============================================================================
          #scrollTo Animation
        ==============================================================================*/


           //dan's version of scroll to animation by hash tag
           $('a[href*="#"]:not([href="#"])').on('click', function(e) {
              if( $(this).attr('href').split('#').slice(0)[0] == window.location.pathname ){
                window.location.hash = $(this).attr('href').split('#').slice(1) ;
                e.preventDefault();
              }
           });


          $( window ).on( 'hashchange', function( e ) {
             e.preventDefault();
             var splitHash = window.location.href.split('#').slice(-1)[0];

              if(splitHash !== '') {
               var target = $('[name='+splitHash+']');
               //console.log(target);
               $('html, body').animate({
                    scrollTop: target.offset().top - 140
               }, 600);
             }
          });

          $(window).load(function() {
            var splitHash = window.location.hash.split('#').slice(-1)[0];

            if(splitHash !== '') {
              var target = $('[name='+splitHash+']');
              //console.log(target);
              $('html, body').animate({
                  scrollTop: target.offset().top - 140
              }, 600);
            }
          });


        /*============================================================================
          #Header shrink down
        ==============================================================================*/
        const initScroll = function() {
        var windowHeight = $(window).height();
        const scroll = window.requestAnimationFrame ||
                       window.webkitRequestAnimationFrame ||
                       window.mozRequestAnimationFrame ||
                       window.msRequestAnimationFrame ||
                       window.oRequestAnimationFrame ||
                       // IE Fallback, you can even fallback to onscroll
                       function(callback) { window.setTimeout(callback, 1000/60); };
          // Loop
          let distance_y = 0;
          let last_position = -1;
          const $global_header = $('.banner');

          function changeHeaderState(y_value) {
            if (y_value > 0) {
              $global_header.addClass('banner--is-shrunk');
            } else {
              $global_header.removeClass('banner--is-shrunk');
            }
          }


          function loop() {

            distance_y = window.pageYOffset || document.documentElement.scrollTop;

            // prevent calculation if not scrolled
            if (last_position === distance_y) {
              scroll(loop);
              return false;
            } else {
              last_position = distance_y;
            }

            // checkForNav();
            changeHeaderState(distance_y);


            // scroll triggered selector
            $('.main > div').each(function(){
              var thisEl = $(this);
              var attr = $(this).attr('name');
              if( typeof attr !== typeof undefined && attr !== false ){
                var rowLastTop = Math.round(thisEl.offset().top - last_position),
                    rowLastBot = Math.round(thisEl.offset().top + thisEl.height() - last_position);


                if( rowLastTop >= 150 && ( rowLastTop + thisEl.height() ) >= rowLastBot ){
                  //do something out of viewport
                  //$('[href*="#"]').removeClass('active-bar');
                  //$('[href*="#'+attr+'"]').removeClass('active-bar');
                }else{
                  //do something in of viewport
                  $('[href*="#"]').removeClass('active-bar');
                  $('[href*="#'+attr+'"]').addClass('active-bar');
                }
              }
            });



            scroll(loop);
          }

          loop();
        };

        initScroll();


        function disableScroll() {
          // $('html, body').css({ overflow:'hidden' });
          $('html').addClass('u-no-scroll');
          // document.ontouchmove = function(e){ e.preventDefault(); };
          // $('.menu-primary-navigation-container').ontouchmove = function(e){ return true; };
        }

        function enableScroll() {
          $('html').removeClass('u-no-scroll');
          // document.ontouchmove = function(e){ return true; };
          // $('html, body').css({ overflow:'' });
        }

        var navPrimary = $('#menu-primary-navigation');
        var navItems = $('>li',navPrimary);

        navItems.each(function(){
          var thisItem = $(this);
          thisItem.on('click',function(){
            var thisItem = $(this);
            if( $('.sub-menu',this).length > 0 ){
              //thisItem.addClass('has--sub-item');
              $('.sub-menu',this).toggleClass('is--active');
            }
          });
        });

        var navToggle = $('.menu-toggle');
        var mobileNav = $('#site-navigation');
        navToggle.on('click',function(){
          $(this).toggleClass('is--active');
          mobileNav.toggleClass('is--active');
        });


        var searchButton = $('.search--button');
        var modalSearch = $('.modal--search');
        var searchField = $('#searchField');


        searchButton.on('click',function(){
          modalSearch.addClass('is--active');

          $('.modal--search__form').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(e) {
            //console.log('anim done');
            searchField.focus();
          });

          modalSearch.on('click',function(e){
            event.stopPropagation();
            modalSearch.removeClass('is--active');
          });

          $('.search-form').on('click',function(e){
            e.stopPropagation();
          });

          $('.modal--search__close').on('click',function(){
            modalSearch.removeClass('is--active');
          });
        });



       /*============================================================================
          Check if element in viewport and fadein.
        ==============================================================================*/
        $(function() {
          $(window).scroll(function() {
            var scrollTop = $(window).scrollTop();
            var viewportHeight = $(window).height();
            $('.slidein:not(.go)').each(function () {
                var top = $(this).offset().top;
                if(scrollTop + viewportHeight >= top ){
                    $(this).addClass('go');
                }
            });
          });
        });

        $(function() {
          $(window).scroll(function() {
            var scrollTop = $(window).scrollTop();
            var viewportHeight = $(window).height();
            $('.anim:not(.go)').each(function () {
                var top = $(this).offset().top;
                if(scrollTop + viewportHeight >= top ){
                    $(this).addClass('go');
                }
            });
          });
        });


        /*============================================================================
          #Filter
        ==============================================================================*/

        (function() {
            var $btn = $('.filter-toggle');
            var $filterBlock = $('.filter__info');
            var $hint = $('.toggle-hint');

            $btn.on('click', function() {
              $filterBlock.slideToggle(300, 'swing');
              $hint.toggleClass('hidden');
            });
        })();

        /*============================================================================
          #Info Overlay
        ==============================================================================*/
        (function() {

            const $close_info_overlay = $('.js-close-info-overlay');
            const $info_overlay_content = $('#info-overlay-content');
            const $btn_open_info = $('.js-open-vessel-info');
            const $info_overlay_container = $('#archive-info-overlay');
            let clicked_el = '';
            const $body = $('body');

            $btn_open_info.on('click', function() {
              clicked_el = this;
              const $self = $(this);
              const target_id = $self.data('post-id');
              $info_overlay_content[0].innerHTML = $(target_id).html();
              $body.addClass('u-no-scroll has-info-overlay');
              $close_info_overlay.focus();
            });

            $close_info_overlay.on('click', function() {
              $body.removeClass('u-no-scroll has-info-overlay');
              $(clicked_el).focus();
            });

            $info_overlay_container.on('click', function() {
              $body.removeClass('u-no-scroll has-info-overlay');
            });

        })();

        (function() {

            const $close_video = $('.js-close-video');
            const $video_overlay_content = $('#info-overlay-content');
            const $btn_open_video = $('.js-open-video');
            const $video_overlay_container = $('#archive-info-overlay');
            let clicked_el = '';
            const $body = $('body');

            $btn_open_video.on('click', function() {
              clicked_el = this;
              const $self = $(this);
              const $videoid = $('.event-1').attr('id');
              const target_id = ('#') + $videoid;
              console.log($videoid);
              $video_overlay_content[0].innerHTML = $(target_id).html();
              $body.addClass('u-no-scroll has-info-overlay has-video-overlay');
              $close_video.focus();
            });

            $close_video.on('click', function() {
              $body.removeClass('u-no-scroll has-info-overlay');
              $video_overlay_content.empty();
              $(clicked_el).focus();
            });

            $video_overlay_container.on('click', function() {
              $body.removeClass('u-no-scroll has-info-overlay');
              $video_overlay_content.empty();
            });

        })();


        /*============================================================================
          # Toggle sub-menus on mobile
        ==============================================================================*/

        $(function() {
            const $navbtn = $('.menu-item-has-children');
            const $navlink = $('.menu-item-has-children > a');
            const $navPrimary = $('#site-navigation');
            // var $submenu = $('.sub-menu');

            $navlink.click(function( event ) {
              if ($('.nav-menu').css('float') === "none" ) {
                $navlink.not($(this)).parent().removeClass('show-sub-menu');
                $(this).parent().toggleClass('show-sub-menu');
                event.preventDefault();
              }
            });

            if ('ontouchstart' in window) {
              $navlink.click(function(e) {
                e.preventDefault();
              });
            }

            window.addEventListener('touchstart', function() {
              $navbtn.on('click', function() {
                $navbtn.removeClass('sub-nav-touch');
                $(this).addClass('sub-nav-touch');
              });
            });

        });

        // object-fit detection ie

        if ( ! Modernizr.objectfit ) {
          $('.single-post__thumbnail').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
              $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('compat-object-fit');
            }
          });
        }


        ///////////////////////////////




      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    // Current Opportunities
    'current_opportunities': {
      init: function() {
        // JavaScript to be fired on the current opportunities page
        console.log('current opportunities');
        let jobTypes = [];
        let jobLocations = [];
        let availableJobs = [];
        let filteredJobs = [];
        let jobs = [];
        // job filter
        function getJobs() {
          var restURL = '/wp-json/wp/v2/careers?per_page=100';

          $.getJSON(restURL, function(data) {
            // console.log(data);
            availableJobs = data;
            jobs = availableJobs;
            outputJobs(jobs);

          });
        }
        getJobs();

        function filterJobs() {
          for(var i = 0; i < availableJobs.length; i++ ) {
            thisJob = availableJobs[i];
           let hasLocation;
           let hasType;

            if(jobLocations.length > 0 ) {
              for (var j = 0; j < jobLocations.length; j++) {
                if( hasLocation === true ) {
                  break;
                }
                if(thisJob.job_location.indexOf(jobLocations[j]) !== -1) {
                  hasLocation = true;
                } else {
                  hasLocation = false;
                }
              }
            }


            if(jobTypes.length > 0 ) {
              for (var k = 0; k < jobTypes.length; k++) {
                if( hasType === true ) {
                  break;
                }
                if(thisJob.job_type.indexOf(jobTypes[k]) !== -1) {
                  hasType = true;
                } else {
                  hasType = false;
                }
              }
            }

            if( hasLocation !== false && hasType !== false ) {
              if( filteredJobs.indexOf(thisJob) === -1) {
                filteredJobs.push(thisJob);
              }
            }
            else {
              if(filteredJobs.indexOf(thisJob) !== -1) {
                var index = filteredJobs.indexOf(thisJob);
                filteredJobs.splice(index, 1);
              }
            }
          }

          jobs = filteredJobs;
          outputJobs(jobs);

          let allFilters = jobLocations.concat(jobTypes);
          let currentFilters = allFilters.join(', ');

          if(window.innerWidth < 1023) {
            $('.selected-filters').html(currentFilters);
          }

          if( currentFilters.length > 0 ) {
            $('.results-button').html('<button>View Results</button>');
          } else {
            $('.results-button').html('<button>Close</button>');
          }

        }


        function outputJobs(jobs) {
          if( jobs.length > 0 ) {

            function compare( a, b ) {
              if ( a.title.rendered < b.title.rendered ){
                return -1;
              }
              if ( a.title.rendered > b.title.rendered ){
                return 1;
              }
              return 0;
            }
            jobs.sort( compare );

            var output = '';
            $.each(jobs, function(key, val) {
              output += '<article class="flex-grid flex-grid--fluid-gutter flex-grid-wrap tr">';
              output += '<div class="col40">';
              output += '<a href="' + val.link + '">';
              if(val.position_title) {
                output += val.position_title
              } else {
                output += val.title.rendered;
              }
              output += '</a>';
              output += '</div>';
              output += '<div class="col30 job-location">';
              output += val.job_location.join(', ');
              output += '</div>';
              output += '<div class="col30">' + val.post_date + '</div>';
              output += '</article>';

            });
            output += '';
            $('#job-postings').html(output);
          } else {
            $('#job-postings').html('<p class="u-pt30">Sorry, no jobs match your criteria. Please refine your search.</p>');
          }
        }


        function getLocations() {
          $('#location-filters span').click(function() {
            var filterName = $(this).data('filter');

            if($(this).hasClass('is-active')) {
              $(this).removeClass('is-active');
              index = jobLocations.indexOf(filterName);
              jobLocations.splice(index, 1);
            } else {
              $(this).addClass('is-active');
              jobLocations.push(filterName);
            }

            if(jobTypes.length > 0 || jobLocations.length > 0 ) {
              if(window.innerWidth > 1023) {
                $('#reset').show();
              } else {
                $('.reset-button').show();
                $('.filter-results').show();
              }
            } else {
              if(window.innerWidth > 1023) {
                $('#reset').hide();
              } else {
                $('.reset-button').hide();
                $('.filter-results').hide();
              }
            }

            filterJobs();
          });
        }
        getLocations();

        function getJobTypes() {
          $('#jobtype-filters span').click(function() {
            var filterName = $(this).data('filter');

            if($(this).hasClass('is-active')) {
              $(this).removeClass('is-active');
              index = jobTypes.indexOf(filterName);
              jobTypes.splice(index, 1);
            } else {
              $(this).addClass('is-active');
              jobTypes.push(filterName);
            }

            if(jobTypes.length > 0 || jobLocations.length > 0 ) {
              if(window.innerWidth > 1023) {
                $('#reset').show();
              } else {
                $('.reset-button').show();
                $('.filter-results').show();
              }
            } else {
              if(window.innerWidth > 1023) {
                $('#reset').hide();
              } else {
                $('.reset-button').hide();
                $('.filter-results').hide();
              }
            }
            filterJobs();
          });
        }
        getJobTypes();

        function resetFilter() {
          filteredJobs = [];
          jobTypes = [];
          jobLocations = [];

          $('#jobtype-filters span').each(function() {
            if($(this).hasClass('is-active')) {
              $(this).removeClass('is-active');
            }
          });

          $('#location-filters span').each(function() {
            if($(this).hasClass('is-active')) {
              $(this).removeClass('is-active');
            }
          });

          $('.results-button').html('<button>Close</button>');
          $('.filter-results').hide();
          jobs = availableJobs;
          outputJobs(jobs);
        }

        $('#reset').click(function() {
          resetFilter();
          $(this).hide();
        });

        $('.reset-button').click(function() {
          resetFilter();
          $(this).hide();
        });

        $('.filter-button').click(function() {
          $('.career-filters').addClass('show-filters');
          $('.filter-overlay').addClass('has-filter-overlay');
          $('body').addClass('u-no-scroll');
        });

        $('.filter-overlay').click(function() {
          $('.career-filters').removeClass('show-filters');
          $('.filter-overlay').removeClass('has-filter-overlay');
          $('body').removeClass('u-no-scroll');
        });

        $('.results-button').click(function() {
          $('.career-filters').removeClass('show-filters');
          $('.filter-overlay').removeClass('has-filter-overlay');
          $('body').removeClass('u-no-scroll');

          $("html,body").animate({scrollTop:$("#app").offset().top - 50
          },"1000");return false;
          // document.getElementById('app').scrollIntoView({behavior: 'smooth', block: 'start'});
        });


      }
    },

    'capacity': {
      init: function() {
        // Javascript to be fired on the capacity page
      }
    }
    //end capacity-page specific js


  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
